import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN-U1-P7-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page24/E1/key/answerKey.png",
    component: DesignUnderLine,
    recorder: true,
    isAllowSubmit: false,
    totalInput: 3,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page24/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/2.jpg",
          audioMainUrl: "Audios/Track 6.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page24/E1/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page24/E1/5.jpg" },
        { url: "img/FriendsPlus/Page24/E1/6.jpg", input: 1, isCorrect: false },
        { url: "img/FriendsPlus/Page24/E1/7.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/8.jpg",
          input: 2,
          isCorrect: true,
          audioUrl: "Audios/Track 6_p24 2.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/9.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page24/E1/10.jpg" },
        { url: "img/FriendsPlus/Page24/E1/11.jpg", input: 3, isCorrect: false },
        { url: "img/FriendsPlus/Page24/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/13.jpg",
          input: 4,
          isCorrect: true,
          audioUrl: "Audios/Track 6_p24 3.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/15.jpg",
          input: 5,
          isCorrect: true,
          audioUrl: "Audios/Track 6_p24 1.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/16.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page24/E1/17.jpg" },
        { url: "img/FriendsPlus/Page24/E1/18.jpg" },
        { url: "img/FriendsPlus/Page24/E1/19.jpg" },
        { url: "img/FriendsPlus/Page24/E1/20.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page24/E1/21.jpg" },
        { url: "img/FriendsPlus/Page24/E1/22.jpg" },
        { url: "img/FriendsPlus/Page24/E1/23.jpg" },
        { url: "img/FriendsPlus/Page24/E1/24.jpg" },
      ],
    ],
  },
};

export default json;
