import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 4',
    id: 'LQTAMN3-U3-P32-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page32/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page32/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/3.jpg', audioUrl: 'Audios/Track 7_hat.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/5.jpg', audioUrl: 'Audios/Track 7_dress.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/7.jpg', audioUrl: 'Audios/Track 7_shoes.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/10.jpg', audioUrl: 'Audios/Track 7_hat.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/12.jpg', audioUrl: 'Audios/Track 7_dress.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/14.jpg', audioUrl: 'Audios/Track 7_shoes.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/15.jpg', audioUrl: 'Audios/Track 7_shorts.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/18.jpg', audioUrl: 'Audios/Track 7_hat.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/20.jpg', audioUrl: 'Audios/Track 7_dress.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/22.jpg', audioUrl: 'Audios/Track 7_shoes.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/23.jpg', audioUrl: 'Audios/Track 7_shorts.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/26.jpg', audioUrl: 'Audios/Track 7_hat.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/28.jpg', audioUrl: 'Audios/Track 7_dress.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/30.jpg', audioUrl: 'Audios/Track 7_shorts.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/31.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/33.jpg', audioUrl: 'Audios/Track 7_T-shirt.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/34.jpg', audioUrl: 'Audios/Track 7_hat.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/36.jpg', audioUrl: 'Audios/Track 7_dress.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/38.jpg', audioUrl: 'Audios/Track 7_shorts.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/41.jpg', audioUrl: 'Audios/Track 7_T-shirt.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/43.jpg', audioUrl: 'Audios/Track 7_dress.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/44.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/45.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/46.jpg', audioUrl: 'Audios/Track 7_T-shirt.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/47.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page32/E1/48.jpg' }],
    ],
  },
}

export default json
