import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN3-U6-P59-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page59/E1/key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "",
        title: `
        `,
        color: "",
      },
    ],
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "328px",
              left: "271px",
              width: 25,
              height: 25,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "328px",
              left: "787px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //1

          {
            boxMatchStyle: {
              position: "absolute",
              top: "502px",
              left: "202px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "502px",
              left: "373px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "502px",
              left: "859px",
              width: 25,
              height: 25,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "502px",
              left: "1004px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //5

          {
            boxMatchStyle: {
              position: "absolute",
              top: "550px",
              left: "241px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "550px",
              left: "567px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //7
        ],
        answers: ["1-2", "3-7", "0-4", "5-6"],
        initialValue: [],
      },
      Layout: `
      
      <img src='img/FriendsPlus/Page59/E1/1.jpg' style="width: 100%"/>
      <input id='0' type= 'boxMatch' />
      <input id='1' type= 'boxMatch' />

      <input id='2' type= 'boxMatch' />
      <input id='3' type= 'boxMatch' />
      <input id='4' type= 'boxMatch' />
      <input id='5' type= 'boxMatch' />

      <input id='6' type= 'boxMatch' />
      <input id='7' type= 'boxMatch' />  
      `,
    },
  },
};

export default json;
