import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 8',
    id: 'LQTAMN3-U8-P77-E1',
    audio: '',
    video: '',
    component: D1,
    // recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        title: ``,
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page77/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page77/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/3.jpg', audioUrl: 'Audios/Track 18_buses.mp3' },
        { url: 'img/FriendsPlus/Page77/E1/4.jpg', audioUrl: 'Audios/Track 18_planes.mp3' },
        { url: 'img/FriendsPlus/Page77/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page77/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page77/E1/7.jpg', audioUrl: 'Audios/Track 18_trains.mp3' },
        { url: 'img/FriendsPlus/Page77/E1/8.jpg' }
      ],
      [{ url: 'img/FriendsPlus/Page77/E1/9.jpg' }],
    ],
  },
}
export default json
