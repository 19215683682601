import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
const json = {
  1: {
    // Exercise num
    unit: 'Unit 4',
    id: 'LQTAMN3-U4-P72-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row

      [{ url: 'img/FriendsPlus/Page72/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page72/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/3.jpg', audioUrl: 'Audios/Track 16_farmer.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/5.jpg', audioUrl: 'Audios/Track 16_student.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page72/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/8.jpg', audioUrl: 'Audios/Track 16_farmer.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/10.jpg', audioUrl: 'Audios/Track 16_teacher.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/12.jpg', audioUrl: 'Audios/Track 16_student.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page72/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/15.jpg', audioUrl: 'Audios/Track 16_farmer.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/17.jpg', audioUrl: 'Audios/Track 16_teacher.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/19.jpg', audioUrl: 'Audios/Track 16_student.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/20.jpg', audioUrl: 'Audios/Track 16_nurse.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page72/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/23.jpg', audioUrl: 'Audios/Track 16_doctor.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/24.jpg', audioUrl: 'Audios/Track 16_farmer.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/25.jpg', audioUrl: 'Audios/Track 16_teacher.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/27.jpg', audioUrl: 'Audios/Track 16_student.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/28.jpg', audioUrl: 'Audios/Track 16_nurse.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/29.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page72/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/31.jpg', audioUrl: 'Audios/Track 16_doctor.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/33.jpg', audioUrl: 'Audios/Track 16_farmer.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/34.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/35.jpg', audioUrl: 'Audios/Track 16_nurse.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/36.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page72/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/38.jpg', audioUrl: 'Audios/Track 16_doctor.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/39.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/40.jpg', audioUrl: 'Audios/Track 16_nurse.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/41.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page72/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/43.jpg', audioUrl: 'Audios/Track 16_doctor.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/44.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/45.jpg', audioUrl: 'Audios/Track 16_who.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/46.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/47.jpg', audioUrl: 'Audios/Track 16_nurse.mp3' },
        { url: 'img/FriendsPlus/Page72/E1/48.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page72/E1/49.jpg' }],
    ],
  },
}

export default json
