import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
  1: {
    unit: 'Unit 3',
    id: 'MN3-U3-P95',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        title: 'Unit 3',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 100,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    maxLength: 1,
    // exerciseKey: 'img/FriendsPlus/Page50/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="position: relative">
          <img src="img/FriendsPlus/Page94/E1/1.jpg" style="width: 950px" />
          <div style="position: absolute;top: 3.5%; left: 25%;">
          <input style="border: none; border-bottom: 1px solid #000; text-align: left; width: 550px;
          background-color: transparent;" />
          </div>
        </div>
          `,
        answer: [],
      },
    ],
  },
}
export default json
