import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 1',
    id: 'LQTAMN3-U1-p82',
    audio: '',
    video: '',
    component: D1,
    // recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        title: `POP IT OUT, THEN USE STICKERS`,
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [[{ url: 'img/FriendsPlus/Page82/E1/1.jpg' }]],
  },
}
export default json
