import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P19-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page19/E1/key/answerKey.png",
    component: DesignUnderLine,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    isAllowSubmit: false,
    totalInput: 8,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page19/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page19/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/3.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/Track 5_p19circle.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/5.jpg",
          input: 2,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19triangle.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/7.jpg",
          input: 2,
          isCorrect: true,
          audioUrl: "Audios/Track 5_p19circle.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/9.jpg",
          input: 2,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19square.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/11.jpg",
          input: 2,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19heart.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/12.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page19/E1/13.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/14.jpg",
          input: 3,
          isCorrect: true,
          audioUrl: "Audios/Track 5_p19star.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/15.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/16.jpg",
          input: 4,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19heart.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/17.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/18.jpg",
          input: 4,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19square.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/19.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/20.jpg",
          input: 4,
          isCorrect: true,
          audioUrl: "Audios/Track 5_p19star.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/21.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/22.jpg",
          input: 4,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19triangle.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/23.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page19/E1/24.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/25.jpg",
          input: 5,
          isCorrect: true,
          audioUrl: "Audios/Track 5_p19square.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/26.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/27.jpg",
          input: 6,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19triangle.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/28.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/29.jpg",
          input: 6,
          isCorrect: true,
          audioUrl: "Audios/Track 5_p19square.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/30.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/31.jpg",
          input: 6,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19star.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/32.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/33.jpg",
          input: 6,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19heart.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/34.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page19/E1/35.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/36.jpg",
          input: 7,
          isCorrect: true,
          audioUrl: "Audios/Track 5_p19heart.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/37.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/38.jpg",
          input: 8,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19star.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/39.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/40.jpg",
          input: 8,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19circle.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/41.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/42.jpg",
          input: 8,
          isCorrect: false,
          audioUrl: "Audios/Track 5_p19triangle.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/43.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/44.jpg",
          input: 8,
          isCorrect: true,
          audioUrl: "Audios/Track 5_p19heart.mp3",
        },
        { url: "img/FriendsPlus/Page19/E1/45.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page19/E1/46.jpg" }],
    ],
  },
};

export default json;
