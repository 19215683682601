import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P12-E1",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [{ url: "img/FriendsPlus/Page12/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page12/E1/3.jpg",
          audioUrl: "Audios/Track 2_p12what.mp3",
        },
        { url: "img/FriendsPlus/Page12/E1/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E1/5.jpg" },
        {
          url: "img/FriendsPlus/Page12/E1/6.jpg",
          audioUrl: "Audios/Track 2_p12what.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/7.jpg",
          audioUrl: "Audios/Track 2_p12pen.mp3",
        },
        { url: "img/FriendsPlus/Page12/E1/8.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E1/9.jpg" },
        {
          url: "img/FriendsPlus/Page12/E1/10.jpg",
          audioUrl: "Audios/Track 2_p12pen.mp3",
        },
        { url: "img/FriendsPlus/Page12/E1/11.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P12-E2",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [{ url: "img/FriendsPlus/Page12/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page12/E2/3.jpg",
          audioUrl: "Audios/Track 2_p12what.mp3",
        },
        { url: "img/FriendsPlus/Page12/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page12/E2/6.jpg",
          audioUrl: "Audios/Track 2_p12what.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E2/7.jpg",
          audioUrl: "Audios/Track 2_p12book.mp3",
        },
        { url: "img/FriendsPlus/Page12/E2/8.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E2/9.jpg" },
        {
          url: "img/FriendsPlus/Page12/E2/10.jpg",
          audioUrl: "Audios/Track 2_p12book.mp3",
        },
        { url: "img/FriendsPlus/Page12/E2/11.jpg" },
        { url: "img/FriendsPlus/Page12/E2/12.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E2/13.jpg" },
        {
          url: "img/FriendsPlus/Page12/E2/14.jpg",
          audioUrl: "Audios/Track 2_p12book.mp3",
        },
        { url: "img/FriendsPlus/Page12/E2/15.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P12-E3",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [{ url: "img/FriendsPlus/Page12/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page12/E3/3.jpg",
          audioUrl: "Audios/Track 2_p12what.mp3",
        },
        { url: "img/FriendsPlus/Page12/E3/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E3/5.jpg" },
        {
          url: "img/FriendsPlus/Page12/E3/6.jpg",
          audioUrl: "Audios/Track 2_p12what.mp3",
        },
        { url: "img/FriendsPlus/Page12/E3/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E3/8.jpg" },
        {
          url: "img/FriendsPlus/Page12/E3/9.jpg",
          audioUrl: "Audios/Track 2_p12what.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E3/10.jpg",
          audioUrl: "Audios/Track 2_p12pencil.mp3",
        },
        { url: "img/FriendsPlus/Page12/E3/11.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E3/12.jpg" },
        {
          url: "img/FriendsPlus/Page12/E3/13.jpg",
          audioUrl: "Audios/Track 2_p12pencil.mp3",
        },
        { url: "img/FriendsPlus/Page12/E3/14.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E3/15.jpg" },
        {
          url: "img/FriendsPlus/Page12/E3/16.jpg",
          audioUrl: "Audios/Track 2_p12pencil.mp3",
        },
        { url: "img/FriendsPlus/Page12/E3/17.jpg" },
      ],
    ],
  },
};

export default json;
