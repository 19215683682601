import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'LQTAMN3-U3-P29-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page29/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page29/E1/2.jpg' },
        {
          url: 'img/FriendsPlus/Page29/E1/3.jpg',
          audioUrl: 'Audios/Track 7_dress.mp3',
        },
        { url: 'img/FriendsPlus/Page29/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E1/5.jpg' },
        {
          url: 'img/FriendsPlus/Page29/E1/6.jpg',
          audioUrl: 'Audios/Track 7_dress.mp3',
        },
        { url: 'img/FriendsPlus/Page29/E1/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E1/8.jpg' },
        {
          url: 'img/FriendsPlus/Page29/E1/9.jpg',
          audioUrl: 'Audios/Track 7_shoes.mp3',
        },
        { url: 'img/FriendsPlus/Page29/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E1/11.jpg' },
        {
          url: 'img/FriendsPlus/Page29/E1/12.jpg',
          audioUrl: 'Audios/Track 7_shoes.mp3',
        },
        { url: 'img/FriendsPlus/Page29/E1/13.jpg' },
        {
          url: 'img/FriendsPlus/Page29/E1/14.jpg',
          audioUrl: 'Audios/Track 7_shoes.mp3',
        },
        { url: 'img/FriendsPlus/Page29/E1/15.jpg' },
        {
          url: 'img/FriendsPlus/Page29/E1/16.jpg',
          audioUrl: 'Audios/Track 7_hat.mp3',
        },
        { url: 'img/FriendsPlus/Page29/E1/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E1/18.jpg' },
        {
          url: 'img/FriendsPlus/Page29/E1/19.jpg',
          audioUrl: 'Audios/Track 7_shoes.mp3',
        },
        { url: 'img/FriendsPlus/Page29/E1/20.jpg' },
        {
          url: 'img/FriendsPlus/Page29/E1/21.jpg',
          audioUrl: 'Audios/Track 7_hat.mp3',
        },
        { url: 'img/FriendsPlus/Page29/E1/22.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page29/E1/23.jpg' }],
    ],
  },
}

export default json
