import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 5',
    id: 'LQTAMN3-U5-P50-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 1,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page50/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page50/E1/2.jpg' },
        {
          url: 'img/FriendsPlus/Page50/E1/3.jpg',
          audioUrl: 'Audios/Track 13.mp3',
        },
        { url: 'img/FriendsPlus/Page50/E1/4.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page50/E1/5.jpg' }],
      [
        { url: 'img/FriendsPlus/Page50/E1/6.jpg' },
        {
          url: 'img/FriendsPlus/Page50/E1/7.jpg',
          audioUrl: 'Audios/Track 12_dance.mp3',
        },
        {
          url: 'img/FriendsPlus/Page50/E1/8.jpg',
          audioUrl: 'Audios/Track 12_sing.mp3',
        },
        {
          url: 'img/FriendsPlus/Page50/E1/9.jpg',
          audioUrl: 'Audios/Track 12_dance.mp3',
        },
        { url: 'img/FriendsPlus/Page50/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page50/E1/11.jpg' },
        {
          url: 'img/FriendsPlus/Page50/E1/12.jpg',
          audioUrl: 'Audios/Track 12_what.mp3',
        },
        { url: 'img/FriendsPlus/Page50/E1/13.jpg' },
        {
          url: 'img/FriendsPlus/Page50/E1/14.jpg',
          audioUrl: 'Audios/Track 12_sing.mp3',
        },
        { url: 'img/FriendsPlus/Page50/E1/15.jpg' },
        {
          url: 'img/FriendsPlus/Page50/E1/16.jpg',
          audioUrl: 'Audios/Track 12_what.mp3',
        },
        { url: 'img/FriendsPlus/Page50/E1/17.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page50/E1/18.jpg' }],
    ],
  },
}

export default json
