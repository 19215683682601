import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
    1: {
        // Exercise num
        unit: "Unit 3",
        id: "LQTAMN3-U3-P28-E1",
        audio: "",
        video: "",
        component: D1,
        recorder: true,
        hideBtnFooter: true,
        titleQuestion: [
            {
                num: "",
                title: ``,
                color: "",
            },
        ],
        isHiddenCheck: true,
        questionImage: [
            [{ url: "img/FriendsPlus/Page28/E1/1.jpg" }],
            [
                { url: "img/FriendsPlus/Page28/E1/2.jpg" },
                {
                    url: "img/FriendsPlus/Page28/E1/3.jpg",
                    audioUrl: "Audios/Track 7.mp3",
                },
                { url: "img/FriendsPlus/Page28/E1/4.jpg" }
            ],
            [{ url: "img/FriendsPlus/Page28/E1/5.jpg" }],
            [
                { url: "img/FriendsPlus/Page28/E1/6.jpg" },
                {
                    url: "img/FriendsPlus/Page28/E1/7.jpg",
                    audioUrl: "Audios/Track 7_T-shirt.mp3",
                },
                { url: "img/FriendsPlus/Page28/E1/8.jpg" }
            ],
            [
                { url: "img/FriendsPlus/Page28/E1/9.jpg" },
                {
                    url: "img/FriendsPlus/Page28/E1/10.jpg",
                    audioUrl: "Audios/Track 7_T-shirt.mp3",
                },
                {
                    url: "img/FriendsPlus/Page28/E1/11.jpg",
                    audioUrl: "Audios/Track 7_shorts.mp3",
                },
                { url: "img/FriendsPlus/Page28/E1/12.jpg" }
            ],
            [
                { url: "img/FriendsPlus/Page28/E1/13.jpg" },
                {
                    url: "img/FriendsPlus/Page28/E1/14.jpg",
                    audioUrl: "Audios/Track 7_T-shirt.mp3",
                },
                {
                    url: "img/FriendsPlus/Page28/E1/15.jpg",
                    audioUrl: "Audios/Track 7_shorts.mp3",
                },
                { url: "img/FriendsPlus/Page28/E1/16.jpg" }
            ],
            [
                { url: "img/FriendsPlus/Page28/E1/17.jpg" },
                {
                    url: "img/FriendsPlus/Page28/E1/18.jpg",
                    audioUrl: "Audios/Track 7_shorts.mp3",
                },
                { url: "img/FriendsPlus/Page28/E1/19.jpg" }
            ],
            [{ url: "img/FriendsPlus/Page28/E1/20.jpg" }],
        ],
    },
};

export default json;
