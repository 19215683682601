import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 5',
    id: 'LQTAMN3-U5-P52-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: 'img/FriendsPlus/Page52/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page52/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/3.jpg', audioUrl: 'Audios/Track 12_fly.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page52/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/6.jpg', audioUrl: 'Audios/Track 12_fly.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/8.jpg', audioUrl: 'Audios/Track 12_dance.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page52/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/11.jpg', audioUrl: 'Audios/Track 12_sing.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/13.jpg', audioUrl: 'Audios/Track 12_dance.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page52/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/16.jpg', audioUrl: 'Audios/Track 12_jump.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/17.jpg', audioUrl: 'Audios/Track 12_sing.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/19.jpg', audioUrl: 'Audios/Track 12_dance.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page52/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/22.jpg', audioUrl: 'Audios/Track 12_jump.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/23.jpg', audioUrl: 'Audios/Track 12_sing.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/24.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page52/E1/25.jpg' }],
      [
        { url: 'img/FriendsPlus/Page52/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/27.jpg', audioUrl: 'Audios/Track 12_what.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/29.jpg', audioUrl: 'Audios/Track 12_eat.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/31.jpg', audioUrl: 'Audios/Track 12_what.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page52/E1/33.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/34.jpg', audioUrl: 'Audios/Track 12_what.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/36.jpg', audioUrl: 'Audios/Track 12_eat.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/38.jpg', audioUrl: 'Audios/Track 12_what.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page52/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/41.jpg', audioUrl: 'Audios/Track 12_what.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/43.jpg', audioUrl: 'Audios/Track 12_what.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/44.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page52/E1/45.jpg' }],
    ],
  },
}

export default json
