import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 5',
    id: 'LQTAMN3-U5-P55-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    recorder: false,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 1,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page55/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page55/E1/2.jpg' },
        {
          url: 'img/FriendsPlus/Page55/E1/3.jpg',
          audioUrl: 'Audios/Track 12_dance.mp3',
        },
        { url: 'img/FriendsPlus/Page55/E1/4.jpg' },
        {
          url: 'img/FriendsPlus/Page55/E1/5.jpg',
          audioUrl: 'Audios/Track 12_eat.mp3',
        },
        { url: 'img/FriendsPlus/Page55/E1/6.jpg' },
        {
          url: 'img/FriendsPlus/Page55/E1/7.jpg',
          audioUrl: 'Audios/Track 12_fly.mp3',
        },
        { url: 'img/FriendsPlus/Page55/E1/8.jpg' },
        {
          url: 'img/FriendsPlus/Page55/E1/9.jpg',
          audioUrl: 'Audios/Track 12_jump.mp3',
        },
        { url: 'img/FriendsPlus/Page55/E1/10.jpg' },
        {
          url: 'img/FriendsPlus/Page55/E1/11.jpg',
          audioUrl: 'Audios/Track 12_sing.mp3',
        },
        { url: 'img/FriendsPlus/Page55/E1/12.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page55/E1/13.jpg' }],
      [
        { url: 'img/FriendsPlus/Page55/E1/14.jpg' },
        {
          url: 'img/FriendsPlus/Page55/E1/15.jpg',
          input: 1,
          isCorrect: true,
          // audioUrl: "Audios/Track 1_p7b.mp3",
        },
        { url: 'img/FriendsPlus/Page55/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page55/E1/17.jpg' },
        {
          url: 'img/FriendsPlus/Page55/E1/18.jpg',
          input: 1,
          isCorrect: true,
          // audioUrl: "Audios/Track 1_p7b.mp3",
        },
        { url: 'img/FriendsPlus/Page55/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page55/E1/20.jpg' },
        {
          url: 'img/FriendsPlus/Page55/E1/21.jpg',
          input: 1,
          isCorrect: true,
          // audioUrl: "Audios/Track 1_p7b.mp3",
        },
        { url: 'img/FriendsPlus/Page55/E1/22.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page55/E1/23.jpg' }],
    ],
  },
}

export default json
