import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 6',
    id: 'LQTAMN3-U6-P57-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    questionImage: [
      [
        {
          url: 'img/FriendsPlus/Page57/E1/1.jpg',
          audioUrl: 'Audios/Track 14.mp3',
        },
      ],
      [
        {
          url: 'img/FriendsPlus/Page57/E1/2.jpg',
          audioUrl: 'Audios/Track 14_swim.mp3',
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 6',
    id: 'LQTAMN3-U6-P57-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    questionImage: [
      [
        {
          url: 'img/FriendsPlus/Page57/E2/1.jpg',
          audioUrl: 'Audios/Track 14.mp3',
        },
      ],
      [
        {
          url: 'img/FriendsPlus/Page57/E2/2.jpg',
          audioUrl: 'Audios/Track 14_tennis.mp3',
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 6',
    id: 'LQTAMN3-U6-P57-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    questionImage: [
      [
        {
          url: 'img/FriendsPlus/Page57/E3/1.jpg',
          audioUrl: 'Audios/Track 14.mp3',
        },
      ],
      [
        {
          url: 'img/FriendsPlus/Page57/E3/2.jpg',
          audioUrl: 'Audios/Track 14_run.mp3',
        },
      ],
    ],
  },
}

export default json
