import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 5',
    id: 'LQTAMN3-U5-P53-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: 'img/FriendsPlus/Page53/E1/1.jpg' }],
      [
        // Column1
        { url: 'img/FriendsPlus/Page53/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/3.jpg', audioUrl: 'Audios/Track 12_dance.mp3' },
        { url: 'img/FriendsPlus/Page53/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/5.jpg', audioUrl: 'Audios/Track 12_sing.mp3' },
        { url: 'img/FriendsPlus/Page53/E1/6.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page53/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/8.jpg', audioUrl: 'Audios/Track 12_dance.mp3' },
        { url: 'img/FriendsPlus/Page53/E1/9.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page53/E1/10.jpg' }],
    ],
  },
}

export default json
