import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 5',
    id: 'LQTAMN3-U5-P51-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page45/E1/Key/answerKey.png',
    component: MatchDots,
    // titleImage: "<img src='img/FriendsPlus/Page19/E2/1.jpg'>",
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: 'calc(13% - 3px)',
              left: 'calc(27% + 2px)',
              width: 25,
              height: 25,
              borderRadius: '50%',
              border: '2px solid red',
            },
          },
          {
            boxMatchStyle: {
              position: 'absolute',
              top: 'calc(49% - 7px)',
              left: 'calc(28% + 7px)',
              width: 25,
              height: 25,
              borderRadius: '50%',
              border: '2px solid red',
            },
          },
          {
            boxMatchStyle: {
              position: 'absolute',
              top: 'calc(75% - 5px)',
              left: 'calc(28% + 7px)',
              width: 25,
              height: 25,
              borderRadius: '50%',
              border: '2px solid red',
            },
          },
          {
            boxMatchStyle: {
              position: 'absolute',
              top: 'calc(85% - 7px)',
              left: 'calc(48% + 9px)',
              width: 25,
              height: 25,
              borderRadius: '50%',
              border: '2px solid red',
            },
          },
          {
            boxMatchStyle: {
              position: 'absolute',
              top: 'calc(20% - 11px)',
              left: 'calc(68% + 9px)',
              width: 25,
              height: 25,
              borderRadius: '50%',
              border: '2px solid red',
            },
          },
          {
            boxMatchStyle: {
              position: 'absolute',
              top: 'calc(49% - 16px)',
              left: 'calc(68% + 9px)',
              width: 25,
              height: 25,
              borderRadius: '50%',
              border: '2px solid red',
            },
          },
          {
            boxMatchStyle: {
              position: 'absolute',
              top: 'calc(80% - 15px)',
              left: 'calc(68% + 9px)',
              width: 25,
              height: 25,
              borderRadius: '50%',
              border: '2px solid red',
            },
          },
          {
            boxMatchStyle: {
              position: 'absolute',
              top: 'calc(95% - 20px)',
              left: 'calc(68% + 9px)',
              width: 25,
              height: 25,
              borderRadius: '50%',
              border: '2px solid red',
            },
          },
          //7
        ],
        answers: ['0-6', '1-4', '2-7', '3-5'],
        initialValue: [],
      },
      Layout: `
      
      <img src='img/FriendsPlus/Page45/E1/3.jpg' style="width: 700px" />
      <input id='0' type= 'boxMatch' />
      <input id='1' type= 'boxMatch' />
      <input id='2' type= 'boxMatch' />
      <input id='3' type= 'boxMatch' />
      <input id='4' type= 'boxMatch' />
      <input id='5' type= 'boxMatch' />
      <input id='6' type= 'boxMatch' />
      <input id='7' type= 'boxMatch' />

      `,
    },
  },
}

export default json
