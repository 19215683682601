import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
  1: {
    // Exercise num
    unit: 'Fluency Time! 2',
    id: 'MN3-U5-P48-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: false,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page48/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page48/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/3.jpg', audioUrl: "Audios/Track 12.mp3" },
        { url: 'img/FriendsPlus/Page48/E1/4.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page48/E1/5.jpg' }],
      [
        { url: 'img/FriendsPlus/Page48/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page48/E1/7.jpg', audioUrl: "Audios/Track 12_what-jump.mp3" },
        { url: 'img/FriendsPlus/Page48/E1/8.jpg', audioUrl: "Audios/Track 12_what-eat.mp3" },
        { url: 'img/FriendsPlus/Page48/E1/9.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page48/E1/10.jpg' }],
    ],
  },
}
export default json
