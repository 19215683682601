import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P11-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 10,
    questionImage: [
      [{ url: "img/FriendsPlus/Page11/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page11/E1/3.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/Track 2_p11pen.mp3",
        },
        { url: "img/FriendsPlus/Page11/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page11/E1/5.jpg",
          input: 2,
          isCorrect: true,
          audioUrl: "Audios/Track 2_p11pencil.mp3",
        },
        { url: "img/FriendsPlus/Page11/E1/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page11/E1/7.jpg" },
        {
          url: "img/FriendsPlus/Page11/E1/8.jpg",
          input: 3,
          isCorrect: true,
          audioUrl: "Audios/Track 2_p11pencil.mp3",
        },
        { url: "img/FriendsPlus/Page11/E1/9.jpg" },
        {
          url: "img/FriendsPlus/Page11/E1/10.jpg",
          input: 4,
          isCorrect: true,
          audioUrl: "Audios/Track 2_p11book.mp3",
        },
        { url: "img/FriendsPlus/Page11/E1/11.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page11/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page11/E1/13.jpg",
          input: 5,
          isCorrect: true,
          audioUrl: "Audios/Track 2_p11bag.mp3",
        },
        { url: "img/FriendsPlus/Page11/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page11/E1/15.jpg",
          input: 6,
          isCorrect: true,
          audioUrl: "Audios/Track 2_p11pen.mp3",
        },
        { url: "img/FriendsPlus/Page11/E1/16.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page11/E1/17.jpg" },
        {
          url: "img/FriendsPlus/Page11/E1/18.jpg",
          input: 7,
          isCorrect: true,
          audioUrl: "Audios/Track 2_p11book.mp3",
        },
        { url: "img/FriendsPlus/Page11/E1/19.jpg" },
        {
          url: "img/FriendsPlus/Page11/E1/20.jpg",
          input: 8,
          isCorrect: true,
          // audioUrl: "Audios/Track 2_p11ruler.mp3",
        },
        { url: "img/FriendsPlus/Page11/E1/21.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E1/22.jpg" }]
    ],
  },
};

export default json;
