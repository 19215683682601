import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
  1: {
    // Exercise num
    unit: 'Unit Starter',
    id: 'LQTAMN3-S-P7-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 2,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page7/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page7/E1/2.jpg' },
        {
          url: 'img/FriendsPlus/Page7/E1/3.jpg',
          input: 1,
          isCorrect: true,
          audioUrl: 'Audios/Track 1_p7a.mp3',
        },
        { url: 'img/FriendsPlus/Page7/E1/4.jpg' },
        {
          url: 'img/FriendsPlus/Page7/E1/5.jpg',
          input: 2,
          isCorrect: true,
          audioUrl: 'Audios/Track 1_p7b.mp3',
        },
        { url: 'img/FriendsPlus/Page7/E1/6.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page7/E1/7.jpg' }],
    ],
  },
}

export default json
