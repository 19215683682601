import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "LQTAMN3-U7-P68-E1",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 1,
    questionImage: [
      [{ url: "img/FriendsPlus/Page68/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page68/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page68/E1/3.jpg",
          audioUrl: "Audios/Track 16.mp3",
        },
        { url: "img/FriendsPlus/Page68/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page68/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page68/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page68/E1/7.jpg",
          audioUrl: "Audios/Track 16_p68teacher.mp3",
        },
        { url: "img/FriendsPlus/Page68/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page68/E1/9.jpg",
          audioUrl: "Audios/Track 16_p68student.mp3",
        },
        { url: "img/FriendsPlus/Page68/E1/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page68/E1/11.jpg" }],
    ],
  },
};

export default json;
