import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P44-E1',
    audio: '',
    video: '',
    component: UI,
    recorder: false,
    isAllowSubmit: false,
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page44/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/13.jpg', audioUrl: 'Audios/Track 11.mp3' },
        { url: 'img/FriendsPlus/Page44/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/33.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/34.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/36.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/38.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/39.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/41.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/43.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/44.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/45.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E1/46.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/47.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/48.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/49.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/50.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/51.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/52.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/53.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/54.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E1/55.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/56.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/57.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/58.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/59.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/60.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/61.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/62.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/63.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E1/64.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/65.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/66.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/67.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/68.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/69.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/70.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/71.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/72.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E1/73.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/74.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/75.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/76.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/77.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/78.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/79.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/80.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/81.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E1/82.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/83.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/84.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/85.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/86.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/87.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/88.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/89.jpg' },
        { url: 'img/FriendsPlus/Page44/E1/90.jpg' },
      ],
    ],
  },
}

export default json
