import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P22-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/E1/3.jpg' },
      ],
    ],
  },
}

export default json
