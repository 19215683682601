import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'LQTAMN3-U3-P30-E1',
    audio: 'Audios/Track 8.mp3',
    video: '',
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page30/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page30/E1/2.jpg' },
        {
          url: 'img/FriendsPlus/Page30/E1/3.jpg',
          audioUrl: 'Audios/Track 8.mp3',
        },
        { url: 'img/FriendsPlus/Page30/E1/4.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page30/E1/5.jpg' }],
      [
        { url: 'img/FriendsPlus/Page30/E1/6.jpg' },
        {
          url: 'img/FriendsPlus/Page30/E1/7.jpg',
          audioUrl: 'Audios/Track 8.mp3',
        },
        { url: 'img/FriendsPlus/Page30/E1/8.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page30/E1/9.jpg' }],
    ],
  },
}

export default json
