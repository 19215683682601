import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'LQTAMN3-U7-P61-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 1,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page61/E1/1.jpg' }],
      [
        {
          url: 'img/FriendsPlus/Page61/E1/2.jpg',
          audioUrl: 'Audios/Track 14_tennis.mp3',
        },
        {
          url: 'img/FriendsPlus/Page61/E1/3.jpg',
          audioUrl: 'Audios/Track 14_basketball.mp3',
        },
      ],
      [{ url: 'img/FriendsPlus/Page61/E1/4.jpg', audioUrl: 'Audios/Track 14_football.mp3' }],
      [{ url: 'img/FriendsPlus/Page61/E1/5.jpg' }],
    ],
  },
}

export default json
