import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
const json = {
  1: {
    // Exercise num
    unit: 'Unit 4',
    id: 'LQTAMN-U4-P40-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page40/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page40/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/3.jpg', audioUrl: 'Audios/Track 9.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/5.jpg', audioUrl: 'Audios/Track 9.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/6.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page40/E1/7.jpg' }],
    ],
  },
}

export default json
