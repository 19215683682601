// import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P43-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    recorder: false,
    isAllowSubmit: false,
    hideBtnFooter: true,
    totalInput: 1,
    questionImage: [
      // Row
      [
        { url: 'img/FriendsPlus/Page43/E1/3.jpg', audioUrl: 'img/FriendsPlus/Page42/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page43/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page42/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page43/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/13.jpg', audioUrl: 'img/FriendsPlus/Page42/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page43/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/15.jpg', audioUrl: 'img/FriendsPlus/Page42/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page43/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/17.jpg', audioUrl: 'img/FriendsPlus/Page42/Audio/tieude.e1.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/24.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page43/E2/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page43/E2/2.jpg' },
        {
          url: 'img/FriendsPlus/Page43/E2/3.jpg',
          input: 1,
          isCorrect: true,
          audioUrl: 'Audios/success-Sound.mp3',
        },
        { url: 'img/FriendsPlus/Page43/E2/4.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page43/E2/5.jpg' }],
      [
        { url: 'img/FriendsPlus/Page43/E2/6.jpg' },
        {
          url: 'img/FriendsPlus/Page43/E2/7.jpg',
          input: 1,
          isCorrect: true,
          audioUrl: 'Audios/success-Sound.mp3',
        },
        { url: 'img/FriendsPlus/Page43/E2/8.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page43/E2/9.jpg' }],
      [
        { url: 'img/FriendsPlus/Page43/E2/10.jpg' },
        {
          url: 'img/FriendsPlus/Page43/E2/11.jpg',
          input: 1,
          isCorrect: true,
          audioUrl: 'Audios/success-Sound.mp3',
        },
        { url: 'img/FriendsPlus/Page43/E2/12.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page43/E2/13.jpg' }],
    ],
  },
}

export default json
