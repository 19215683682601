import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
  1: {
    unit: 'Unit 1',
    id: 'MN3-U1-P93',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        title: 'Unit 1',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 100,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    maxLength: 1,
    // exerciseKey: 'img/FriendsPlus/Page50/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="position: relative">
          <img src="img/FriendsPlus/Page93/E1/1.jpg" style="width: 500px" />
          <div style="position: absolute;top: 28%; left: 46%;">
          <input style="border: none; border-bottom: 1px solid #000; text-align: left; width: 77px;
          background-color: transparent;" />
          </div>
        </div>
          `,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 2',
    id: 'MN3-U2-P93',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        title: 'Unit 2',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 100,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    maxLength: 1,
    // exerciseKey: 'img/FriendsPlus/Page50/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="position: relative">
          <img src="img/FriendsPlus/Page93/E2/1.jpg" style="width: 500px" />
          <div style="position: absolute;top: 12%; left: 17%;">
          <input style="border: none; border-bottom: 1px solid #000; text-align: left; width: 120px;
          background-color: transparent;" />
          </div>
        </div>
          `,
        answer: [],
      },
    ],
  },
}
export default json
