import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
const json = {
  1: {
    // Exercise num
    unit: 'Unit 4',
    id: 'LQTAMN3-U4-P39-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row

      [{ url: 'img/FriendsPlus/Page39/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page39/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/3.jpg', audioUrl: 'Audios/Track 9_big.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/6.jpg', audioUrl: 'Audios/Track 9_big.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/8.jpg', audioUrl: 'Audios/Track 9_small.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/9.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page39/E1/10.jpg' }],
      [
        { url: 'img/FriendsPlus/Page39/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/12.jpg', audioUrl: 'Audios/Track 9_long.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/14.jpg', audioUrl: 'Audios/Track 9_short.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/17.jpg', audioUrl: 'Audios/Track 9_long.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page39/E1/19.jpg', audioUrl: 'Audios/Track 9_short.mp3' },
        { url: 'img/FriendsPlus/Page39/E1/20.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page39/E1/21.jpg' }],
    ],
  },
}

export default json
