import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import UI from "../../components/ExcerciseTypes/Design/UserInterface";


const json = {
    1: {
        // Exercise num
        unit: "Unit 1",
        id: "LQTAMN3-U1-P10-E1",
        audio: "Audios/Track 3.mp3",
        video: "",
        component: D1,
        recorder: true,
        hideBtnFooter: true,
        titleQuestion: [
            {
                num: "",
                title: ``,
                color: "",
            },
        ],
        isHiddenCheck: true,
        // isAllowSubmit: false,
        totalInput: 0,
        questionImage: [
            [{ url: "img/FriendsPlus/Page10/E1/1.jpg" },],
            [
                { url: "img/FriendsPlus/Page10/E1/2.jpg" },
                {
                    url: "img/FriendsPlus/Page10/E1/3.jpg",
                    audioUrl: "Audios/Track 3.mp3",
                },
                { url: "img/FriendsPlus/Page10/E1/4.jpg" },
            ],
            [
                { url: "img/FriendsPlus/Page10/E1/5.jpg" },
                {
                    url: "img/FriendsPlus/Page10/E1/6.jpg",
                    audioUrl: "Audios/Track 3_p10bag.mp3",
                },
                {
                    url: "img/FriendsPlus/Page10/E1/7.jpg",
                    audioUrl: "Audios/Track 3_p10book.mp3",
                },
                { url: "img/FriendsPlus/Page10/E1/8.jpg" },
            ],
            [
                { url: "img/FriendsPlus/Page10/E1/9.jpg" },
                {
                    url: "img/FriendsPlus/Page10/E1/10.jpg",
                    audioUrl: "Audios/Track 3_p10pen.mp3",
                },
                {
                    url: "img/FriendsPlus/Page10/E1/11.jpg",
                    audioUrl: "Audios/Track 3_p10pencil.mp3",
                },
                {
                    url: "img/FriendsPlus/Page10/E1/12.jpg",
                    audioUrl: "Audios/Track 3_p10ruler.mp3",
                },
                { url: "img/FriendsPlus/Page10/E1/13.jpg" },
            ],
            [{ url: "img/FriendsPlus/Page10/E1/14.jpg" },],
        ],
    },


}

export default json;