import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 4',
    id: 'LQTAMN-U4-P37-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page37/E1/Key/answerKey.png',
    component: D1,
    recorder: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page37/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/14.jpg', audioUrl: 'Audios/Track 10.mp3' }, //audio
        { url: 'img/FriendsPlus/Page37/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/24.jpg' }, //voi
        { url: 'img/FriendsPlus/Page37/E1/25.jpg' }, //voi
        { url: 'img/FriendsPlus/Page37/E1/26.jpg' }, //voi
        { url: 'img/FriendsPlus/Page37/E1/27.jpg' }, //voi
      ],
      [
        { url: 'img/FriendsPlus/Page37/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/31.jpg' }, // ran
        { url: 'img/FriendsPlus/Page37/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/33.jpg' }, //voi
        { url: 'img/FriendsPlus/Page37/E1/34.jpg' }, //voi
        { url: 'img/FriendsPlus/Page37/E1/35.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E1/36.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/38.jpg' }, //ran
        { url: 'img/FriendsPlus/Page37/E1/39.jpg' }, //ran
        { url: 'img/FriendsPlus/Page37/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/41.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/43.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E1/44.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/45.jpg' }, //ran
        { url: 'img/FriendsPlus/Page37/E1/46.jpg' }, //ran
        { url: 'img/FriendsPlus/Page37/E1/47.jpg' }, //ran
        { url: 'img/FriendsPlus/Page37/E1/48.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/49.jpg' }, //sau
        { url: 'img/FriendsPlus/Page37/E1/50.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/51.jpg' }, //sau
      ],
      [{ url: 'img/FriendsPlus/Page37/E1/52.jpg' }, { url: 'img/FriendsPlus/Page37/E1/53.jpg' }, { url: 'img/FriendsPlus/Page37/E1/54.jpg' }],
    ],
  },
}

export default json
