import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
const json = {
  1: {
    // Exercise num
    unit: 'Unit 1',
    id: 'LQTAMN3-U1-P20-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    // isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 1,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page20/E1/2.jpg' }],
      [
        { url: 'img/FriendsPlus/Page20/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/4.jpg', audioUrl: 'Audios/Track 5_p19heart.mp3' }, //2
        { url: 'img/FriendsPlus/Page20/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/6.jpg', audioUrl: 'Audios/Track 5_p19circle.mp3' }, //3
        { url: 'img/FriendsPlus/Page20/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/8.jpg', audioUrl: 'Audios/Track 5_p19star.mp3' }, //4
        { url: 'img/FriendsPlus/Page20/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/11.jpg', audioUrl: 'Audios/Track 5_p19heart.mp3' }, //2
        { url: 'img/FriendsPlus/Page20/E1/12.jpg', audioUrl: 'Audios/Track 5_p19circle.mp3' }, //3
        { url: 'img/FriendsPlus/Page20/E1/13.jpg', audioUrl: 'Audios/Track 5_p19star.mp3' }, //4
        { url: 'img/FriendsPlus/Page20/E1/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/16.jpg', audioUrl: 'Audios/Track 5_p19triangle.mp3' }, // 1
        { url: 'img/FriendsPlus/Page20/E1/17.jpg', audioUrl: 'Audios/Track 5_p19heart.mp3' }, // 2
        { url: 'img/FriendsPlus/Page20/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/19.jpg', audioUrl: 'Audios/Track 5_p19circle.mp3' }, // 3
        { url: 'img/FriendsPlus/Page20/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/21.jpg', audioUrl: 'Audios/Track 5_p19star.mp3' }, // 4
        { url: 'img/FriendsPlus/Page20/E1/22.jpg', audioUrl: 'Audios/Track 5_p19square.mp3' }, // 5
        { url: 'img/FriendsPlus/Page20/E1/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/24.jpg', audioUrl: 'Audios/Track 5_p19circle.mp3' }, //3
        { url: 'img/FriendsPlus/Page20/E1/25.jpg', audioUrl: 'Audios/Track 5_p19triangle.mp3' }, // 1
        { url: 'img/FriendsPlus/Page20/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/27.jpg', audioUrl: 'Audios/Track 5_p19square.mp3' }, // 5
        { url: 'img/FriendsPlus/Page20/E1/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/30.jpg', audioUrl: 'Audios/Track 5_p19triangle.mp3' }, // 1
        { url: 'img/FriendsPlus/Page20/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/32.jpg', audioUrl: 'Audios/Track 5_p19square.mp3' }, //5
        { url: 'img/FriendsPlus/Page20/E1/33.jpg' },
      ],
    ],
  },
}

export default json
