import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {
    1: {
        // Exercise num
        unit: "Unit 1",
        id: "LQTAMN3-U1-P14-E1",
        audio: "",
        video: "",
        component: D1,
        recorder: true,
        hideBtnFooter: true,
        titleQuestion: [
            {
                num: "",
                title: ``,
                color: "",
            },
        ],
        isHiddenCheck: true,
        // isAllowSubmit: false,
        totalInput: 0,
        questionImage: [
            [{ url: "img/FriendsPlus/Page14/E1/1.jpg" }],
            [
                { url: "img/FriendsPlus/Page14/E1/2.jpg" },
                {
                    url: "img/FriendsPlus/Page14/E1/3.jpg",
                    audioUrl: "Audios/Track 2_p14bag.mp3",
                },
                {
                    url: "img/FriendsPlus/Page14/E1/4.jpg",
                },
                { url: "img/FriendsPlus/Page14/E1/5.jpg" },
            ],
            [{ url: "img/FriendsPlus/Page14/E1/6.jpg" }],
        ],
    },
}

export default json;