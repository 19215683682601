import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'LQTAMN3-U7-STUDENT-CARDS',
    audio: '',
    video: '',
    component: D1,
    // recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        title: `Unit 7`,
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page92/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/5.jpg' },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 8',
    id: 'LQTAMN3-U8-STUDENT-CARDS',
    audio: '',
    video: '',
    component: D1,
    // recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        title: `Unit 8`,
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page92/E2/1.jpg' }, { url: 'img/FriendsPlus/Page92/E2/2.jpg' }, { url: 'img/FriendsPlus/Page92/E2/3.jpg' }],
      [{ url: 'img/FriendsPlus/Page92/E2/4.jpg' }, { url: 'img/FriendsPlus/Page92/E2/5.jpg' }],
    ],
  },
}
export default json
