import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 6',
    id: 'LQTAMN3-U6-P60-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: false,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 1,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page60/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page60/E1/2.jpg' },
        {
          url: 'img/FriendsPlus/Page60/E1/3.jpg',
          audioUrl: 'Audios/Track 14_basketball.mp3',
        },
        {
          url: 'img/FriendsPlus/Page60/E1/4.jpg',
          audioUrl: 'Audios/Track 14_swim.mp3',
        },
        { url: 'img/FriendsPlus/Page60/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page60/E1/6.jpg' },
        {
          url: 'img/FriendsPlus/Page60/E1/7.jpg',
          audioUrl: 'Audios/Track 14_tennis.mp3',
        },
        { url: 'img/FriendsPlus/Page60/E1/8.jpg' },
        {
          url: 'img/FriendsPlus/Page60/E1/9.jpg',
          audioUrl: 'Audios/Track 14_basketball.mp3',
        },
        {
          url: 'img/FriendsPlus/Page60/E1/10.jpg',
          audioUrl: 'Audios/Track 14_swim.mp3',
        },
        { url: 'img/FriendsPlus/Page60/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page60/E1/12.jpg' },
        {
          url: 'img/FriendsPlus/Page60/E1/13.jpg',
          audioUrl: 'Audios/Track 14_tennis.mp3',
        },
        { url: 'img/FriendsPlus/Page60/E1/14.jpg' },
        {
          url: 'img/FriendsPlus/Page60/E1/15.jpg',
          audioUrl: 'Audios/Track 14_football.mp3',
        },
        {
          url: 'img/FriendsPlus/Page60/E1/16.jpg',
          audioUrl: 'Audios/Track 14_run.mp3',
        },
        { url: 'img/FriendsPlus/Page60/E1/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page60/E1/18.jpg' },
        {
          url: 'img/FriendsPlus/Page60/E1/19.jpg',
          audioUrl: 'Audios/Track 14_football.mp3',
        },
        {
          url: 'img/FriendsPlus/Page60/E1/20.jpg',
          audioUrl: 'Audios/Track 14_run.mp3',
        },
        { url: 'img/FriendsPlus/Page60/E1/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page60/E1/22.jpg' },
        {
          url: 'img/FriendsPlus/Page60/E1/23.jpg',
          // audioUrl: "Audios/Track 13.mp3",
        },
        { url: 'img/FriendsPlus/Page60/E1/24.jpg' },
        {
          url: 'img/FriendsPlus/Page60/E1/25.jpg',
          // audioUrl: "Audios/Track 13.mp3",
        },
        { url: 'img/FriendsPlus/Page60/E1/26.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page60/E1/27.jpg' }],
    ],
  },
}

export default json
