import PaintColorType from '../../components/ExcerciseTypes/Design/PaintColorType'
const json = {
  1: {
    // Exercise num
    unit: 'Unit 1',
    id: 'LQTAMN-U1-P25-E1',
    audio: '',
    video: '',
    component: PaintColorType,
    recorder: true,
    isAllowSubmit: true,
    totalInput: 5,
    isHiddenCheck: true,
    styleQuestionImage: [
      {
        width: 600,
      },
    ],
    questionImage: [{ url: 'img/FriendsPlus/Page25/E1/1.jpg' }],
    questionSVG: [
      [
        {
          viewBox: '0 0 16 16',
          audioUrl: 'Audios/It is a triangle.mp3',
          input: 1,
          isCorrect: true,
          style: {
            width: 140,
            height: 140,
            marginTop: 9,
            stroke: 'black',
            strokeWidth: 0.1,
            fill: 'transparent',
            cursor: 'pointer',
          },
          g: `
          <polygon
          id= "1"
          points='8,3 16,11 0,11'
        />
          `,
        },
        {
          viewBox: '0 0 455 455',
          audioUrl: 'Audios/It is a cricle.mp3',
          input: 2,
          isCorrect: true,
          style: {
            width: 90,
            height: 90,
            marginTop: '-13px',
            stroke: 'black',
            strokeWidth: 5,
            fill: 'transparent',
            cursor: 'pointer',
          },
          g: `
          <circle id="2" cx='227.5' cy='227.5' r='227.5'/>
        />
          `,
        },
        {
          audioUrl: 'Audios/It is a heart.mp3',
          input: 3,
          isCorrect: true,
          viewBox: '0 0 15 15',
          style: {
            width: 120,
            height: 120,
            cursor: 'pointer',

            stroke: 'black',
            strokeWidth: 0.2,
            fill: 'transparent',
          },
          g: `
          <path
          id="3"
          d='M5.301 3.002c-.889-.047-1.759.247-2.404.893-1.29 1.292-1.175 3.49.26 4.926l.515.515L8.332 14l4.659-4.664.515-.515c1.435-1.437 1.55-3.634.26-4.926-1.29-1.292-3.483-1.175-4.918.262l-.516.517-.517-.517C7.098 3.438 6.19 3.049 5.3 3.002z'
        />
          `,
        },
        {
          audioUrl: 'Audios/It is a square.mp3',
          input: 4,
          isCorrect: true,
          style: {
            width: 90,
            height: 90,
            marginTop: '20px',
            marginLeft: '20px',
            stroke: 'black',
            strokeWidth: 2,
            fill: 'transparent',
            cursor: 'pointer',
          },
          g: `
          <rect
          id="4"
          x={113}
          y={0}
          width="90px"
          height="90px"
        />
          `,
        },
        {
          audioUrl: 'Audios/It is a star.mp3',
          input: 5,
          isCorrect: true,
          x: 0,
          y: 0,
          viewBox: '0 0 53.867 53.867',
          style: {
            width: 90,
            height: 90,
            marginTop: '15px',

            marginLeft: '20px',
            stroke: 'black',
            strokeWidth: 1,
            fill: 'transparent',
            cursor: 'pointer',
          },
          g: `
          <polygon
          id="5"
          points='26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 
	10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 '
        />
          `,
        },
      ],
    ],
    listColorQuestion: [
      {
        color: 'red',
      },
      {
        color: 'Pink',
      },
      {
        color: 'yellow',
      },
      {
        color: 'blue',
      },
      {
        color: 'green',
      },
    ],
  },
}

export default json
