import UI from '../../components/ExcerciseTypes/Design/UserInterface'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB1-U2-P18-E1',
    audio: '',
    video: 'Videos/Unit 2_Track 5_If you know!.mp4',
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: 'img/FriendsPlus/Page18/E1/1.jpg' }],
      [
        // Column2
        { url: 'img/FriendsPlus/Page18/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page18/E1/3.jpg', audioMainUrl: 'Audios/Track 5.mp3' },
        { url: 'img/FriendsPlus/Page18/E1/4.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page18/E1/5.jpg' }],
    ],
  },
}

export default json
