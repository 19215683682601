import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 1 & 2',
    id: 'LQTAMN3-U1&2-P26-E1',
    audio: '',
    video: '',
    component: D1,
    // recorder: true,
    // isAllowSubmit: false,
    questionImage: [[{ url: 'img/FriendsPlus/Page26/E1/1.jpg' }]],
  },
}

export default json
